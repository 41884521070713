// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    end_point: 'https://bogu.one/api/v1',
    CLOUDFLARE: {
        TURNSTILE: {
            KEY: '0x4AAAAAAAfgkeBYyfPBcvgV',
        }
    },
    BET_STATUS: {
        R: '대기',
        C: '취소',
        W: '적중',
        F: '실패',
    },
    WIN_STATUS: {
        R: '대기',
        C: '취소',
        W: '적중',
        F: '실패',
        V: '적특',
    },
    CASH_STATUS: {
        R: '신청',
        C: '취소',
        F: '완료',
    },
    CASH_TYPE: {
        CASH: '캐쉬',
        POINT: '포인트',
        CREDIT: '크레딧'
    },
    COUPON_TYPE: {
        DEPOSIT: '입금보너스',
        ANY: '',
    },
    INFO_MEM_STATUS: {
        M: '정상회원',
        B: '접근금지',
        G: '졸업',
        O: '탈퇴',
        B2: '방출',
    },
    INFO_SIGNUP_STATUS: {
        R: '가입신청',
        R1: '가입심사중',
        R2: '가입거부',
    },
    INFO_MEMO: {
        UR: '읽지않음',
        R: '읽음',
        D: '삭제',
    },
    INTO_GAME: {
        SPORTS: '스포츠',
        CASINO: '카지노',
        MINI: '미니게임',
    },
    INFO_SPORTS: {
        PREMATCH: '프리매치',
        INPLAY: '인플레이',
        CROSS: '크로스베팅',
        SPECIAL: '스페셜',
    },
    INFO_MINI: {
        NAMED: '네임드',
        NTRY: 'NTRY',
        LIVESCORE: '라이브스코어',
        BET365: 'BET365',
    },
    ATTENDANCE_TYPE: {
        P: '포인트',
        C: '크레딧'
    },
    MINI: {
        NAMED: {
            'powerball3': 'NAMED_NPOWERBALL3',
            'powerball5': 'NAMED_NPOWERBALL5',
            'powerladder3': 'NAMED_NPOWERLADDER3',
            'powerladder5': 'NAMED_NPOWERLADDER5',
            'red-powerball': 'NAMED_RPOWERBALL',
            'red-powerladder': 'NAMED_RPOWERLADDER',
        },
        NTRY: {
            'powerball': 'NTRY_POWERBALL',
            'power-ladder': 'NTRY_POWERLADDER',
            'speedkeno': 'NTRY_SPEEDKENO',
            'keno-ladder': 'NTRY_KENOLADDER',
        },
        NTRY_EOS: {
            'pb1': 'NTRY_EOS_POWERBALL1',
            'pb2': 'NTRY_EOS_POWERBALL2',
            'pb3': 'NTRY_EOS_POWERBALL3',
            'pb5': 'NTRY_EOS_POWERBALL5',
        },
        LIVESCORE: {
            'power-freekick': 'LIVESCORE_POWER_FREEKICK',
            'speed-homerun': 'LIVESCORE_SPEED_HOMERUN',
            'powerball': 'LIVESCORE_POWERBALL',
            'speedkeno': 'LIVESCORE_SPEEDKENO',
        },
    
    }
};

export type environmentOptions = {
    [ key: string ] : string | number | undefined;
}
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
