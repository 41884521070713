import {
    OnInit,
    OnChanges,
    Directive,
    Input,
    HostBinding,
    Renderer2,
    ElementRef,
    SimpleChanges
} from "@angular/core";
  
import { v4 } from 'uuid';

/**
 * app.module.ts declarations LoadingDirective
 * <div [appLoading]="isLoading">
 */

@Directive({
    selector: "[appLoading]",
})
export class LoadingDirective implements OnInit, OnChanges {
    @HostBinding("style.position")
    hostPosition: string = "relative";
  
    @Input() appLoading: boolean = false;
  
    uuid: string;
  
    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {
        this.uuid = "loading-container-" + v4();
    }
  
    ngOnInit() {
        const loadingContainer = this.renderer.createElement("div");
        // let css = ``;
        // this.elementRef.nativeElement.setAttribute("style", css);

        this.renderer.setStyle(loadingContainer,"display",this.appLoading ? "flex" : "none");
        this.renderer.setStyle(loadingContainer, "justify-content", "center");
        this.renderer.setStyle(loadingContainer, "align-items", "center");
        this.renderer.addClass(loadingContainer, this.uuid);
        this.renderer.setStyle(loadingContainer, "position", "absolute");
        this.renderer.setStyle(loadingContainer, "top", "0");
        this.renderer.setStyle(loadingContainer, "background", "#e4e4e4");
        this.renderer.setStyle(loadingContainer, "width", "100%");
        this.renderer.setStyle(loadingContainer, "height", "100%");
    
        // custom spinner -- start
        const spinnerContainer = this.renderer.createElement("div");
        this.renderer.addClass(spinnerContainer, "lds-facebook");
        const spinnerInnerDiv1 = this.renderer.createElement("div");
        const spinnerInnerDiv2 = this.renderer.createElement("div");
        const spinnerInnerDiv3 = this.renderer.createElement("div");
    
        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv1);
        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv2);
        this.renderer.appendChild(spinnerContainer, spinnerInnerDiv3);
    
        this.renderer.appendChild(loadingContainer, spinnerContainer);
        // custom spinner -- end
    
        this.renderer.appendChild(this.elementRef.nativeElement, loadingContainer);
    }
  
    ngOnChanges(simpleChanges: SimpleChanges) {
        if(simpleChanges['appLoading']) {
            const container = this.elementRef.nativeElement;
            const div = container.querySelector("." + this.uuid);
            if(div) this.renderer.setStyle(div, "display", this.appLoading ? "flex" : "none");
        }
    }
}
  