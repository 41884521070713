import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mini',
  templateUrl: './mini.component.html',
  styles: [
  ]
})
export class MiniComponent implements OnInit {
    private subs: Subscription[] = [];
    menu:string | undefined;
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) { 
        const data:any = this.activatedRoute.snapshot.firstChild?.data;
        this.menu = data['code']
    }

    ngOnInit(): void {
        this.subs.push(
            this.router.events.subscribe((event) => {
                if(event instanceof NavigationEnd) {
                    const data:any = this.activatedRoute.snapshot.firstChild?.data;
                    this.menu = data['code']
                }
            })
        );
    }

    ngOnDestroy():void {
        this.subs.map(s => s.unsubscribe());
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();
        if(!url) url = ($event.currentTarget as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
}
