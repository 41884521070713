
    <ul class="navbar-nav">
        <li class="nav-item d-flex justify-content-between py-2">
            <span class="fw-bold">
                <i class="fa-solid fa-bell"></i>
                <span class="text-yellow">인플레이</span>
            </span>
            <span>{{ today }}</span>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-1"></b>축구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-2"></b>농구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-3"></b>야구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-4"></b>아이스하키</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-5"></b>테니스</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-6"></b>핸드볼</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-11"></b>모터 스포츠</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-12"></b>럭비</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-21"></b>크리켓</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-22"></b>다트</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-23"></b>배구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-29"></b>풋살</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-31"></b>배드민턴</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item d-flex justify-content-between py-2">
            <span class="fw-bold">
                <span class="text-yellow">오늘의 경기</span>
            </span>
            <span>{{ today }}</span>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-1"></b>축구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-3"></b>야구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-2"></b>농구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-23"></b>배구</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-4"></b>아이스하키</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link d-flex justify-content-between p-1" href="#">
                <span><b class="sc-110"></b>E-Sports</span>
                <span class="badge bg-dark">0</span>
            </a>
        </li>
    </ul> 
