import { Component, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ApiService } from 'src/service/api.service';


@Injectable({
    providedIn: 'root'
})
export class NoticeService {
    public items_notice:any = new BehaviorSubject<any>([])
    constructor(
        private apiService:ApiService,
    ) {
        this.fnGetData()
    }

    public getData() {
        return this.items_notice.asObservable() 
    }

    fnGetData() {
        this.apiService.get("/site/notice").subscribe(jsondata => {
            if(jsondata.success) {
                this.items_notice.next(jsondata.data)
            }
        });
    }
}
