import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpStatusCode
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, map, retry, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private isRefreshing = false;

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            // HttpStatusCode.Unauthorized 401 
            // HttpStatusCode.Forbidden 403
            if([ 401, 403 ].includes(err.status)) {
                sessionStorage.setItem('signout', JSON.stringify(err.error));
                this.router.navigate(['signout']);
            } else if( [ 404 ].includes(err.status)){
            } else if([ 406 ].includes(err.status)) {
                return this.handle406Error(request, next)
            } else if([ 0 ].includes(err.status)) {
            }

            const error = (err && err.error && err.error.message) || err.statusText;
            console.error(err);
            return throwError(() => error);
        }))
    }

    // refreshToken
    private handle406Error(request: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            const token = sessionStorage.getItem('refresh_token')
            if(token) {
                return this.authService.updateToken().pipe(
                    switchMap((jsondata: any) => {
                        this.isRefreshing = false;
                        
                        this.refreshTokenSubject.next(jsondata.data.access_token);
                        request = request.clone({
                            headers: request.headers.set(`Authorization`,`Bearer ${token}`)
                        });
                        
                        return next.handle(request);
                    }),
                    catchError((err) => {
                        this.isRefreshing = false;
                        
                        return throwError(() => err)
                    })
                )
            }
        }

        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => {
                request = request.clone({
                    headers: request.headers.set(`Authorization`,`Bearer ${token}`)
                });
                return next.handle(request)
            })
        )
    }
}

export const ErrorInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];

