<div class="container-fluid">
    <div class="d-flex align-items-center justify-content-between py-3">
        <div class="d-xl-none">
            <button  type="button" class="btn fs-3 text-white" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu">
                <i class="fa-solid fa-bars"></i>
            </button>
        </div>
        <div class="col-xl-2">
            <a href="/" (click)="Go($event)" class="header-brand">INPLAY</a>
        </div>
        <div class="col-xl-8 d-none d-xl-block py-3">
            <div class="gnb d-flex justify-content-between align-items-center px-5">
                <div [ngClass]="{ 'active': currentMenu == 'prematch' }"><a href="/prematch" (click)="Go($event)">프리매치</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'inplay' }"><a href="/inplay" (click)="Go($event)">인플레이</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'cross-bet' }"><a href="/cross-bet" (click)="Go($event)">크로스벳</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'special' }"><a href="/special" (click)="Go($event)">스페셜</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'casino' }"><a href="/casino" (click)="Go($event)">카지노</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'mini' }"><a href="/mini" (click)="Go($event)">미니게임</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'bets' }"><a href="/bets" (click)="Go($event)">베팅내역</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'notice' }"><a href="/notice" (click)="Go($event)">공지사항</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'event' }"><a href="/event" (click)="Go($event)">이벤트</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'community' }"><a href="/community" (click)="Go($event)">커뮤니티</a></div>
                <div [ngClass]="{ 'active': currentMenu == 'customer-center'}"><a href="/customer-center" (click)="Go($event)">고객센터</a></div>
            </div>
        </div>
        <div class="col-xl-2 d-none d-xl-block text-end">
            {{ time }}
            <i class="fi fi-kr"></i>
        </div>
        <div class="d-xl-none">
            <ng-template [ngIf]="!showPageTop">
                <ng-template [ngIf]="cart == 'mini'">
                    <button type="button" class="btn fs-3 text-white position-relative" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCart" >
                        <i class="fa-solid fa-cart-shopping"></i>
                        <sup class="cart-total">
                            {{ betSlip.length  }}
                        </sup>
                    </button>
                </ng-template>
            </ng-template>
        </div>
    </div>
</div>
<div class="offcanvas offcanvas-start d-flex" data-bs-scroll="false" data-bs-backdrop="true" tabindex="-1" id="offcanvasMenu">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title">
            <ng-template [ngIf]="!currentUser">
                <i class="fa-solid fa-user"></i>
                Member Sign Inn
            </ng-template>
            <ng-template [ngIf]="currentUser">
                <i class="fa-solid fa-user"></i>
                Member Info
            </ng-template>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body pt-1">
        <ng-template [ngIf]="!currentUser">
            <div class="d-flex">
                    <div class="col-8">
                        <div class="">
                            <input class="form-control form-control-sm" type="text" placeholder="아이디">
                        </div>
                        <div class="mt-1">
                            <input class="form-control form-control-sm" type="password" placeholder="비밀번호">
                        </div>
                    </div>
                    <div class="col-4 d-grid ps-1">
                        <button type="button" class="btn btn-primary btn-sm">로그인</button>
                    </div>
                </div>
                <div class="d-flex my-1">
                    <div class="col-12 d-flex">
                        <div class="col-6 d-grid">
                            <button type="button" class="btn btn-primary btn-sm" routerLink="/signup">
                                <i class="fa-solid fa-user-plus"></i>
                                회원가입
                            </button>
                        </div>
                        <div class="col-6 d-grid ps-1">
                            <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#request-modal">
                                <i class="fa fa-edit"></i>
                                비회원문의
                            </button>
                        </div>
                    </div>
                </div>
        </ng-template>
        <ng-template [ngIf]="currentUser">
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    <span *ngIf="currentUser" class="me-1"><img [attr.src]="site.getIcon(currentUser.level)" style="width:24px;height:24px;" *ngIf="site.getIcon(currentUser.level)"></span>
                    <span *ngIf="currentUser">{{ currentUser.nick }}</span>
                </div>
                <button class="btn btn-secondary btn-sm" (click)="signOut()">
                    <i class="fa-solid fa-sign-out"></i>
                        로그아웃
                </button>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-envelope me-1"></i>
                    쪽지함
                </div>
                <span class="badge bg-dark text-sm" routerLink="/memo" role="button">{{ currentUser.memo | number }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-won-sign me-1"></i>
                    보유머니
                </div>
                <span class="text-yellow">{{ currentUser.cash | number}}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-parking me-1"></i>
                    보유포인트
                </div>
                <span class="text-primary">{{ currentUser.point | number }}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-cent-sign me-1"></i>
                    보유크레딧
                </div>
                <span class="text-danger">{{ currentUser.credit | number}}</span>
            </div>
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex align-items-center">
                    <i class="fa-solid fa-circle-dollar-to-slot me-1"></i>
                    카지노머니
                </div>
                <span class="text-info">{{ currentUser.casino_money | number}}</span>
            </div>
            <div class="row mt-1">
                <div class="col-6 d-grid pe-1">
                    <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, '/money/deposit')">
                        <i class="fa-solid fa-money-bill-alt"></i>
                        충전신청
                    </button>
                </div>
                <div class="col-6 d-grid ps-0">
                    <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/money/withdraw')">
                        <i class="fa-solid fa-comments-dollar"></i>
                        환전신청
                    </button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 d-grid pe-1">
                    <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, '/money/point')">
                        <i class="fa-solid fa-sync"></i>
                        포인트전환
                    </button>
                </div>
                <div class="col-6 d-grid ps-0">
                    <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/memo')">
                        <i class="fa-solid fa-envelope"></i>
                        쪽지함
                    </button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 d-grid pe-1">
                    <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, 'bets')">
                        <i class="fa-solid fa-bars"></i>
                        베팅내역
                    </button>
                </div>
                <div class="col-6 d-grid ps-0">
                    <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/mypage/attendance')">
                        <i class="fa-solid fa-clipboard-check"></i>
                        출석체크
                    </button>
                </div>
            </div>
            <div class="row mt-1">
                <div class="col-6 d-grid pe-1">
                    <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, '/mypage')">
                        <i class="fa-solid fa-user"></i>
                        마이페이지
                    </button>
                </div>
                <div class="col-6 d-grid ps-0">
                    <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/mypage/referral')">
                        <i class="fa-solid fa-user-friends"></i>
                        추천인내역
                    </button>
                </div>
            </div>
        </ng-template>
        <ul class="mobile-gnb mt-3 p-0">
            <li class="fs-5" (click)="Go($event, '/prematch')"><i class="fa-solid fa-calendar-days me-1"></i>프리매치</li>
            <li class="fs-5" (click)="Go($event, '/inplay')"><i class="fa-solid fa-clock me-1"></i>인플레이</li>
            <li class="fs-5" (click)="Go($event, '/cross-bet')"><i class="fa-solid fa-futbol me-1"></i>크로스벳</li>
            <li class="fs-5" (click)="Go($event, '/special')"><i class="fa-solid fa-medal me-1"></i>스페셜</li>
            <li class="fs-5" (click)="Go($event, '/casino')"><i class="fa-solid fa-clover me-1"></i>카지노</li>
            <li class="fs-5" (click)="Go($event, '/mini')"><i class="fa-solid fa-dice me-1"></i>미니게임</li>
            <li class="fs-5" (click)="Go($event, '/bets')"><i class="fa-solid fa-book-open me-1"></i>베팅내역</li>
            <li class="fs-5" (click)="Go($event, '/notice')"><i class="fa-solid fa-exclamation-circle me-1"></i>공지사항</li>
            <li class="fs-5" (click)="Go($event, '/event')"><i class="fa-solid fa-gift me-1"></i>이벤트</li>
            <li class="fs-5" (click)="Go($event, '/community')"><i class="fa-solid fa-comments me-1"></i>커뮤니티</li>
            <li class="fs-5" (click)="Go($event, '/customer-center')"><i class="fa fa-headset me-1"></i>고객센터</li>
        </ul>
    </div>
</div>
<div class="offcanvas offcanvas-end d-flex" data-bs-scroll="false" data-bs-backdrop="true" tabindex="-1" id="offcanvasCart">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title">
            <div class="d-flex align-items-center">
                <i class="fa-solid fa-shopping-cart"></i>
                <span class="ms-1">Betting Cart </span>
            </div>
        </h5>
        <button type="button" class="btn-close" data-bs-dismiss="offcanvas"></button>
    </div>
    <div class="offcanvas-body item-grid pt-1">
    </div>
</div>
<router-outlet></router-outlet>
<footer>
    <div class="bt">By accessing, continuing to use or navigating throughout this site you accept that we will use certain browser cookies to improve your customer experience with us. INPLAY only uses cookies which will improve your experience with us and will not interfere with your privacy. </div>
        <div class="bt c"> © <b>INPLAY.</b> 2022. ALL RIGHTS RESERVED. 
    </div>
</footer>
<div class="modal fade" id="request-modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">비회원 문의</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-center">
                <textarea class="form-control" rows="10"></textarea>
                <div class="text-yellow py-2">* 텔레그램아이디 또는 휴대폰번호를 꼭 남겨주시기 바랍니다.</div>
            </div>
            <div class="text-center my-3">
                <button type="button" class="btn btn-primary btn-sm">작성완료</button>
                <button type="button" class="btn btn-danger btn-sm ms-1" data-bs-dismiss="modal">취소</button>
            </div>
        </div>
    </div>
</div>
<div class="page-top" *ngIf="showPageTop" @fadeIn @fadeOut>
    <ng-template *ngIf="cart">
        <button type="button" class="btn fs-3 text-white position-relative" data-bs-toggle="offcanvas" data-bs-target="#offcanvasCart" >
            <i class="fa-solid fa-cart-shopping"></i>
            <sup class="cart-total">
                {{ betSlip.length  }}
            </sup>
        </button>
    </ng-template>
    <button type="button" (click)="fnScrollToTop()">
        <i class="fa-solid fa-chevron-up fa-2x"></i>
    </button>
</div>