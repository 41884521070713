
<ng-container *ngFor="let items of popups">
    <div class="popup-wrap">
        <div class="popup-layer" [attr.id]="items.id" @fadeIn @fadeOut>
            <div class="close-button-wrap me-2"> 
                <button class="popup_layer_close" (click)="fnClosePopup(items.idx)">
                    <i class="far fa-times-circle fa-lg"></i>
                </button>
            </div>
            <div class="popup-content" (click)="fnClosePopup(items.idx)" role="button">
                <span [innerHTML]="items.content | sanitize"></span>
            </div>
        </div>
    </div>
</ng-container>
<div class="container-fluid">
    <div class="row">
        <div class="d-none d-xl-block col-xl-2 px-0">
            <shared-event-list></shared-event-list>
        </div>
        <div class="col-xl-8">
            <div class="main-slide">
                <swiper 
                    [lazy]="{loadPrevNext : true}"
                    [loop]="true" 
                    [autoplay]="{ delay: 3000, disableOnInteraction: false }" 
                    [pagination]="{ clickable: true }" 
                    [navigation]="false"
                    (swiper)="onSwiper($event)"
                    (slideChange)="onSlideChange()">
                    <ng-template class="swiper-slide" swiperSlide let-data>
                        <img src="/assets/images/bg-main01.png?v=4" class="swiper-lazy"/>
                        <a routerLink="/prematch" class="main-join-left fadeinup" [ngClass]="data.isActive ? '' : 'none'">PLAY NOW</a>
                    </ng-template>
                    <ng-template class="swiper-slide" swiperSlide let-data>
                        <img src="/assets/images/bg-main02.png?v=4" class="swiper-lazy"/>
                        <a routerLink="/inplay" class="main-join-right fadeinup" [ngClass]="data.isActive ? '' : 'none'">PLAY NOW</a>
                    </ng-template>
                    <ng-template class="swiper-slide" swiperSlide let-data>
                        <img src="/assets/images/bg-main03.png?v=3" class="swiper-lazy"/>
                        <a routerLink="/casino" class="main-join-left fadeinup" [ngClass]="data.isActive ? '' : 'none'">PLAY NOW</a>
                    </ng-template>
                </swiper>
            </div>
            <div class="row mt-2">
                <div class="col-xl-6 mt-1 p-2">
                    <div class="events-list-header d-flex justify-content-between align-items-center py-2">
                        <div class="d-flex align-items-center ms-2 ">
                            <i class="fa-solid fa-bell text-yellow"></i>
                            <span class="ms-1">프리매치</span>
                            <span class="text-yellow ms-1">Prematch</span>
                        </div>
                        <button type="button" class="btn btn-secondary btn-sm me-2" routerLink="/prematch">전체보기</button>
                    </div>
                    <div class="competition">
                        <span class="ms-2">
                            <b class="sc-1"></b>
                            <span class="me-1">축구</span>
                            <i class="fa-solid fa-angle-double-right me-2"></i>
                            <span><img src="https://img.sportradar.com/ls/crest/big/en.png" width="20" class="me-1" /></span>
                            <b class="me-2">잉글랜드 프리미어리그</b>
                        </span>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xl-text-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xl-text-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xl-text-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xl-text-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xltext-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xl-text-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                    <div class="event-list-item row g-0 align-items-center" data-competition-id="900" data-match-id="64294" data-bet-type="90007" data-game-type="3">
                        <div class="col-xl-2 text-xs xl-text-center">
                            01-17 23:49
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>왓포드</span>
                            <span class="text-yellow">2.58</span>
                        </div>
                        <div class="col-3 col-xl-2 d-flex justify-content-between align-items-center px-2" data-choice="1">
                            <span>무</span>
                            <span class="text-yellow">2.79</span>
                        </div>
                        <div class="col-4 col-xl-4 d-flex justify-content-between align-items-center px-2">
                            <span class="text-yellow">2.06</span>
                            <span>토트넘</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 mt-1 p-2">
                    <div class="events-list-header d-flex justify-content-between align-items-center py-2">
                        <div class="d-flex align-items-center ms-2 ">
                            <i class="fa-solid fa-bell text-red"></i>
                            <span class="ms-1">라이브매치</span>
                            <span class="text-red ms-1">Inplay</span>
                        </div>
                        <button type="button" class="btn btn-secondary btn-sm me-2" routerLink="/inplay">전체보기</button>
                    </div>
                    <div class="events-list-competition row align-items-center g-0">
                        <div class="col-8 d-xl-flex">
                            <div class="ms-1">
                                <span class="sc-1 me-1"></span>
                                <span class="me-1">축구</span>
                                <i class="fa fa-angle-double-right me-1"></i>
                            </div>
                            <div class="ps-1">
                                <img src="/assets/region/4.svg" width="20" class="me-1">
                                <b class="league">FIFA WORLDCUP 카타르 2022 </b>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-end align-items-center p-1">
                            <div class="d-xl-flex text-yellow me-1">
                                <div class="me-1">01-22</div>
                                <div>11:11</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex text-white">
                        <div class="col-3 bg-dark text-center">
                            <div class="mt-2">
                                <span>후반전</span>
                                <span class="ms-1">90"</span>
                            </div>
                            <div class="mt-1">
                                <span class="badge bg-danger p-2">100</span> 
                                <span class="text-yellow mx-1">vs</span>
                                <span class="badge bg-danger p-2">100</span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="item-border-right d-flex p-2">
                                <div class="col-5 text-center">이탈리아</div>
                                <div class="col-2 text-center text-yellow">VS</div>
                                <div class="col-5 text-center">마케도니아</div>
                            </div>
                            <div class="event-list-item d-flex">
                                <div class="col-6 d-flex justify-content-between px-2">
                                    <small class="text-gray">홈팀승</small>
                                    <span class="text-orange">1.54</span>
                                </div>
                                <div class="col-6 d-flex justify-content-between px-2">
                                    <small class="text-gray">원정팀승</small>
                                    <span class="text-orange">5.54</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="events-list-competition row align-items-center g-0">
                        <div class="col-8 d-xl-flex">
                            <div class="ms-1">
                                <span class="sc-1 me-1"></span>
                                <span class="me-1">축구</span>
                                <i class="fa fa-angle-double-right me-1"></i>
                            </div>
                            <div class="ps-1">
                                <img src="/assets/region/4.svg" width="20" class="me-1">
                                <b class="league">FIFA WORLDCUP 카타르 2022 </b>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-end align-items-center p-1">
                            <div class="d-xl-flex text-yellow me-1">
                                <div class="me-1">01-22</div>
                                <div>11:11</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex text-white">
                        <div class="col-3 bg-dark text-center">
                            <div class="mt-2">
                                <span>후반전</span>
                                <span class="ms-1">90"</span>
                            </div>
                            <div class="mt-1">
                                <span class="badge bg-danger p-2">100</span> 
                                <span class="text-yellow mx-1">vs</span>
                                <span class="badge bg-danger p-2">100</span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="item-border-right d-flex p-2">
                                <div class="col-5 text-center">이탈리아</div>
                                <div class="col-2 text-center text-yellow">VS</div>
                                <div class="col-5 text-center">마케도니아</div>
                            </div>
                            <div class="event-list-item d-flex">
                                <div class="col-6 d-flex justify-content-between px-2">
                                    <small class="text-gray">홈팀승</small>
                                    <span class="text-orange">1.54</span>
                                </div>
                                <div class="col-6 d-flex justify-content-between px-2">
                                    <small class="text-gray">원정팀승</small>
                                    <span class="text-orange">5.54</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="events-list-competition row align-items-center g-0">
                        <div class="col-8 d-xl-flex">
                            <div class="ms-1">
                                <span class="sc-1 me-1"></span>
                                <span class="me-1">축구</span>
                                <i class="fa fa-angle-double-right me-1"></i>
                            </div>
                            <div class="ps-1">
                                <img src="/assets/region/4.svg" width="20" class="me-1">
                                <b class="league">FIFA WORLDCUP 카타르 2022 </b>
                            </div>
                        </div>
                        <div class="col-4 d-flex justify-content-end align-items-center p-1">
                            <div class="d-xl-flex text-yellow me-1">
                                <div class="me-1">01-22</div>
                                <div>11:11</div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex text-white">
                        <div class="col-3 bg-dark text-center">
                            <div class="mt-2">
                                <span>후반전</span>
                                <span class="ms-1">90"</span>
                            </div>
                            <div class="mt-1">
                                <span class="badge bg-danger p-2">100</span> 
                                <span class="text-yellow mx-1">vs</span>
                                <span class="badge bg-danger p-2">100</span>
                            </div>
                        </div>
                        <div class="col-9">
                            <div class="item-border-right d-flex p-2">
                                <div class="col-5 text-center">이탈리아</div>
                                <div class="col-2 text-center text-yellow">VS</div>
                                <div class="col-5 text-center">마케도니아</div>
                            </div>
                            <div class="event-list-item d-flex">
                                <div class="col-6 d-flex justify-content-between px-2">
                                    <small class="text-gray">홈팀승</small>
                                    <span class="text-orange">1.54</span>
                                </div>
                                <div class="col-6 d-flex justify-content-between px-2">
                                    <small class="text-gray">원정팀승</small>
                                    <span class="text-orange">5.54</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row p-1">
                <div class="col-6 col-xl-3 mt-1 p-0">
                    <div class="small-contents m-1">
                        <img src="/assets/images/sbg-main-01.png" class="w-100"/>
                        <span class="site">INPLAY</span>
                        <span class="title">SPORTS</span>
                        <div class="btn-play">
                            <a routerLink="/prematch" data-hover="Play Now">SPORTS</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-xl-3 mt-1 p-0">
                    <div class="small-contents m-1">
                        <img src="/assets/images/sbg-main-02.png" class="w-100"/>
                        <span class="site">INPLAY</span>
                        <span class="title">LIVE BETTING</span>
                        <div class="btn-play">
                            <a routerLink="/inplay" data-hover="Play Now">IN-PLAY</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-xl-3 mt-1 p-0">
                    <div class="small-contents m-1">
                        <img src="/assets/images/sbg-main-03.png" class="w-100"/>
                        <span class="site">INPLAY</span>
                        <span class="title">CASINO</span>
                        <div class="btn-play">
                            <a routerLink="/casino" data-hover="Play Now">CASINO</a>
                        </div>
                    </div>
                </div>
                <div class="col-6 col-xl-3 mt-1 p-0">
                    <div class="small-contents m-1">
                        <img src="/assets/images/sbg-main-04.png?v=2" class="w-100"/>
                        <span class="site">INPLAY</span>
                        <span class="title">MINIGAME</span>
                        <div class="btn-play">
                            <a routerLink="/mini" data-hover="Play Now">MINIGAME</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-none d-xl-block col-xl-2 text-white ps-0">
            <shared-sign-in></shared-sign-in>
            <shared-notice></shared-notice>
        </div>
    </div>
</div>

