<div class="container-fluid">
    <div class="row">
        <div class="d-none d-xl-block col-xl-2 px-0">
            <shared-event-list></shared-event-list>
        </div>
        <div class="col-xl-8">
            <h5 class="content-header"><i class="fa-solid fa-dice"></i> 미니게임 - Mini Game </h5>
            <div class="row g-0 minigame-list" *ngIf="menu">
                <div class="col-xl-2 col-4" [ngClass]="{ 'active' : menu == 'NAMED'}" (click)="Go($event, '/mini/named')" role="button">
                    <b class="named-ico"></b>
                    네임드
                </div>
                <div class="col-xl-2 col-4" [ngClass]="{ 'active' : menu == 'NTRY'}" (click)="Go($event, '/mini/ntry')" role="button">
                    <b class="sc-1003"></b>
                    엔트리
                </div>
                <div class="col-xl-2 col-4" [ngClass]="{ 'active' : menu == 'NTRY_EOS'}" (click)="Go($event, '/mini/ntry-eos')" role="button">
                    <b class="sc-1003"></b>
                    엔트리 EOS
                </div>
                <div class="col-xl-2 col-4" [ngClass]="{ 'active' : menu == 'LIVESCORE'}" (click)="Go($event, '/mini/livescore')" role="button">
                    <b class="livescore-ico"></b>
                    라이브스코어
                </div>
                <div class="col-xl-2 col-4" [ngClass]="{ 'active' : menu == 'BET365'}" (click)="Go($event, '/mini/bet365')" role="button">
                    <b class="sc-1000"></b>
                    BET365
                </div>
            </div>
            <router-outlet></router-outlet>
        </div>
        <div class="d-none d-xl-block col-xl-2 text-white ps-0">
            <shared-sign-in></shared-sign-in>
            <app-mini-bet-slip *ngIf="menu"></app-mini-bet-slip>
            <shared-notice></shared-notice>
        </div>
    </div>
</div>