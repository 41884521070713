import { Injectable } from '@angular/core';
import * as moment  from 'moment-timezone';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TimerService {
    private subs: Subscription[] = [];
    
    private current = new BehaviorSubject<any>(''); // 시간을 sync 한다.
    everySecond: Observable<number> = timer(0, 1000);

    constructor() { 
        this.current.next(moment.tz('Asia/Seoul'));
        this.subs.push(
            this.everySecond.subscribe((seconds) => {
                this.current.next(this.current.getValue().add(1, 'seconds'));
            })
        );
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    public getDate() {
        return this.current.asObservable() 
    }

    public setDate(date:string) {
        const d = moment.tz(date, 'Asia/Seoul')
        const current = this.current.getValue()
        if(current.format('X') !== d.format('X')) {
            this.current.next(d)
        }
    }
}

