import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { PaginationService } from './pagination-service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styles: [
  ]
})
export class PaginationComponent implements OnInit, OnChanges {
    @Input() total: number = 0;
    @Input() page: number = 1;
    @Input() limit: number = 20;
    @Input() pageBlock: number = 10;
    
    @Input() btnFirst: boolean = false; // 맨 처음으로
    @Input() btnLast: boolean = false; // 맨 뒤로
    
    
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    pagination: any = {};
    constructor(
        private paginationService: PaginationService,
    ) { }

    ngOnInit(): void {
        this.pagination = this.paginationService.init(this.total, this.page, this.limit, this.pageBlock, this.btnFirst, this.btnLast);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.pagination = this.paginationService.init(this.total, this.page, this.limit, this.pageBlock, this.btnFirst, this.btnLast);
    }

    Go(page: number, $event: any = null) {
        if($event) $event.preventDefault();
        this.pageChange.emit(page);
    }
}
