import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BetSlipService {
    private items:any = new BehaviorSubject<any[]>([]);
    private mini:any = new BehaviorSubject<any>(null);

    constructor() {}

    get length() {
        return this.items.getValue().length
    }

    public getData() {
        return this.items.asObservable() 
    }

    public getMini() {
        return this.mini.asObservable()
    }

    setItems(items:any = []) {
        this.items.next(items)
    }

    addItem(item: any) {
        const items = this.items.getValue()
        items.push(item)
        this.items.next(items)
    }

    getItems() {
        return this.items.getValue();
    }

    clearItems() {
        const items:any = [];
        this.items.next(items)
    }

    removeItems(index:number) {
        this.items.getValue().splice(index, 1)
        this.items.next(this.items.getValue())
    }

    // 미니게임 정보
    setMini(game: string) {
        // 다른 미니게임 삭제
        const items = this.items.getValue().filter((v:any) => {
            v.leagueId == game
        })

        this.mini.next({ game: game })
        this.items.next(items)
    }

}