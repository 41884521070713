import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { LoadingService } from './loading.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.css'],
    encapsulation: ViewEncapsulation.ShadowDom,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
    private subs: Subscription[] = [];

    loading:number = 0;
    constructor(
        public loadingService: LoadingService,
        private changeDetectorRef: ChangeDetectorRef
    ) {

    }

    ngOnInit(): void {
        this.subs.push(
            this.loadingService.getLoading().subscribe((v:boolean) => {
                if(v) this.loading++
                else this.loading--

                if(this.loading < 0) this.loading = 0;
                this.changeDetectorRef.detectChanges();
            })
        );

    }

    ngOnDestroy(): void {
        this.subs.map(s => s.unsubscribe());
    }
}