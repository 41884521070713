import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mypage',
  templateUrl: './mypage.component.html',
  styles: [
  ]
})
export class MypageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
