<div class="sub-header p-2 mt-3">
    <i class="fa-solid fa-shopping-cart"></i>
    Betting Cart
</div>
<div [ngClass]="{ 'lock': is_lock || !roundInfo?.is_enabled || roundInfo?.is_lock }">
    <ng-container *ngFor="let items of betSlip.getItems(); let i = index;">
        <div class="events-list-competition row g-0 p-2">
            <div class="col-11">
                <span>{{ items.leagueName }}</span>
            </div>
            <div class="col-1 text-yellow text-end">
                <a href="#" (click)="fnDelete(i, $event)">
                    <i class="fa-regular fa-rectangle-xmark"></i>
                </a>
            </div>
        </div>
        <div class="item item-border-left d-flex p-2">
            <span>{{ items.gameTimesDay }} 회차</span>
        </div>
        <div class="item item-border-left d-flex p-2">
            <span>{{ items.marketName }}</span>
        </div>
        <div class="item item-border-left d-flex justify-content-between p-2">
            <span>{{ items.choiceName }}</span>
            <span class="text-red">{{ items.choicePrice}}</span>
        </div>        
    </ng-container>
    <form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
        <div class="row g-0">
            <div class="col-4 p-1">보유머니</div>
            <div class="col-8 p-1 text-end text-yellow">{{ currentUser?.cash | number }}</div>
        </div>
        <div class="row g-0">
            <div class="col-4 p-1">보유크레딧</div>
            <div class="col-8 p-1 text-end text-blue">{{ currentUser?.credit | number }}</div>
        </div>
        <div class="row g-0 align-items-center">
            <div class="col-4 p-1">베팅금액</div>
            <div class="col-8 p-1">
                <input type="text" formControlName="amount" class="form-control form-control-sm text-end" style="max-width:200px;" numbersOnly placeholder="" required autocomplete="off" (keyup)="fnCalculate()" [ngClass]="{ 'is-invalid': submitted && f['amount'].errors }"> 
                <div *ngIf="submitted && f['amount'].errors" class="invalid-feedback">
                    <div *ngIf="f['amount'].errors['required']">베팅금액을 입력하세요.</div>
                    <div *ngIf="f['amount'].errors['check_error']">{{ f['amount'].errors['check_error'] }}</div>
                </div>
            </div>
        </div>
        <div class="row g-0">
            <div class="col-4 p-1">총 배당률</div>
            <div class="col-8 p-1 text-end">{{ hope_rate | number:'1.0-2'}}</div>
        </div>
        <div class="row g-0">
            <div class="col-4 p-1">예상당첨금</div>
            <div class="col-8 p-1 text-end">{{ hope_amount | number :'1.0-0'}}</div>
        </div>
        <div class="row g-0 my-1">
            <div class="col-6 d-grid pe-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(10000)">10,000</button>
            </div>
            <div class="col-6 d-grid ps-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(30000)">30,000</button>
            </div>
        </div>
        <div class="d-flex my-1">
            <div class="col-6 d-grid pe-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(50000)">50,000</button>
            </div>
            <div class="col-6 d-grid ps-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(100000)">100,000</button>
            </div>
        </div>
        <div class="d-flex my-1">
            <div class="col-6 d-grid pe-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(300000)">300,000</button>
            </div>
            <div class="col-6 d-grid ps-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(500000)">500,000</button>
            </div>
        </div>
        <div class="d-flex my-1">
            <div class="col-6 d-grid pe-1">
                <button type="button" class="btn btn-dark btn-sm" (click)="fnSetAmount(1000000)">1,000,000</button>
            </div>
            <div class="col-6 d-grid ps-1">
                <button type="button" class="btn btn-dark btn-sm text-yellow" (click)="fnSetAmount(0)">초기화</button>
            </div>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" formControlName="use_credit" id="use_credit1111" role="button" (change)="fnCalculate()">
            <label class="form-check-label" for="use_credit1111" role="button">벳 크레딧 사용</label>
        </div>
        <div class="d-grid my-1">
            <button type="button" class="btn btn-warning btn-sm" (click)="fnSetMax()">MAX Betting</button>
        </div>
        <div class="d-flex my-1">
            <div class="col-6 d-grid pe-1">
                <button type="button" class="btn btn-danger btn-sm" (click)="onSubmit()">베팅하기</button>
            </div>
            <div class="col-6 d-grid ps-1">
                <button type="button" class="btn btn-secondary btn-sm" (click)="fnDelete()">전체삭제</button>
            </div>
        </div>
    </form>
</div>


