import { NgModule } from '@angular/core';
import { TurnstileComponent } from './turnstile.component';



@NgModule({
    declarations: [
        TurnstileComponent
    ],
    exports: [
        TurnstileComponent
    ]
})
export class TurnstileModule { }
