import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard'
import { BetsComponent } from './bets/bets.component';
import { CasinoComponent } from './casino/casino.component';
import { CommunityComponent } from './community/community.component';
import { CrossBetComponent } from './cross-bet/cross-bet.component';
import { CustomerCenterComponent } from './customer-center/customer-center.component';
import { EventComponent } from './event/event.component';
import { ErrorComponent } from './home/error.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './home/main.component';
import { SignInComponent } from './auth/sign-in.component';
import { SignOutComponent } from './auth/sign-out.component';
import { SignUpComponent } from './auth/sign-up.component';
import { InplayComponent } from './inplay/inplay.component';
import { MemoComponent } from './memo/memo.component';
import { MiniComponent } from './mini/mini.component';
import { MoneyComponent } from './money/money.component';
import { MypageComponent } from './mypage/mypage.component';
import { NoticeComponent } from './notice/notice.component';
import { PrematchComponent } from './prematch/prematch.component';
import { SpecialComponent } from './special/special.component';
import { userRole } from './auth/auth.service';


const routes: Routes = [
    {
        path: '', component: HomeComponent, 
        children: [
            { path: '', pathMatch: 'full', component: MainComponent, runGuardsAndResolvers: 'pathParamsOrQueryParamsChange' , canActivate: [AuthGuard], data: { role: userRole.LEVEL1 } },
            { path: 'prematch', component: PrematchComponent,  loadChildren: () => import('./prematch/prematch.module').then(m => m.PrematchModule) },
            { path: 'inplay', component: InplayComponent,  loadChildren: () => import('./inplay/inplay.module').then(m => m.InplayModule) },
            { path: 'cross-bet', component: CrossBetComponent,  loadChildren: () => import('./cross-bet/cross-bet.module').then(m => m.CrossBetModule) },
            { path: 'special', component: SpecialComponent,  loadChildren: () => import('./special/special.module').then(m => m.SpecialModule) },
            { path: 'casino', component: CasinoComponent,  loadChildren: () => import('./casino/casino.module').then(m => m.CasinoModule) },
            { path: 'mini', component: MiniComponent, loadChildren: () => import('./mini/mini.module').then(m => m.MiniModule) },
            { path: 'money', component: MoneyComponent,  loadChildren: () => import('./money/money.module').then(m => m.MoneyModule) },
            { path: 'mypage', component: MypageComponent,  loadChildren: () => import('./mypage/mypage.module').then(m => m.MypageModule) },
            { path: 'bets', component: BetsComponent, loadChildren: () => import('./bets/bets.module').then(m => m.BetsModule) },
            { path: 'notice', component: NoticeComponent, loadChildren: () => import('./notice/notice.module').then(m => m.NoticeModule) },
            { path: 'event', component: EventComponent, loadChildren: () => import('./event/event.module').then(m => m.EventModule) },
            { path: 'customer-center', component: CustomerCenterComponent, loadChildren: () => import('./customer-center/customer-center.module').then(m => m.CustomerCenterModule) },
            { path: 'community', component: CommunityComponent, loadChildren: () => import('./community/community.module').then(m => m.CommunityModule) },
            { path: 'memo', component: MemoComponent, loadChildren: () => import('./memo/memo.module').then(m => m.MemoModule) },
        ],
    },
    { path: 'signin', component: SignInComponent },
    { path: 'signout', component: SignOutComponent },
    { path: 'signup', component: SignUpComponent },
    { path: '**', component: ErrorComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
