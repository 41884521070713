import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Component({
  selector: 'app-sign-out',
  template: '',
  styles: [
  ]
})
export class SignOutComponent implements OnInit {

    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    ngOnInit(): void {
        this.authService.signOut()
        this.router.navigateByUrl('/signin');
    }

}
