import { Component, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/service/api.service';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { DecimalPipe } from '@angular/common';
import * as moment from 'moment-timezone'
import { TimerService } from 'src/service/timer.service';
import { Router } from '@angular/router';
import { BetSlipService } from 'src/service/bet-slip.service';

@Component({
  selector: 'app-cross-bet-slip',
  templateUrl: './cross-bet-slip.component.html',
  styles: [
  ]
})
export class CrossBetSlipComponent {
    private subs: Subscription[] = [];

    form1!: FormGroup;
    submitted = false;
    sigining = false;
    

    hope_rate:number = 0;
    hope_amount:number = 0;

    roundInfo:any = {}
    kickoff:moment.Moment | undefined
    bet_close: moment.Moment | undefined;
    result_time: moment.Moment | undefined;

    is_lock:boolean = false

    set_min_bet:number = 0  // 최소베팅금
    set_max_win:number = 0; // 최대 당첨금
    set_max_bet:number = 0  // 최대 베팅
    max_win_line:number = 0; // 축베팅

    currentUser!:currentUserInfo | null

    get f () { return this.form1.controls }
    get betSlip() { return this.betSlipService }
    constructor(
        private betSlipService: BetSlipService,
        private formBuilder: FormBuilder,
        private apiService: ApiService,
        private authService: AuthService,
        private decimalPipe: DecimalPipe,
        private router: Router
    ) {
        this.form1 = this.formBuilder.group({
            amount: ['0', Validators.required],
            use_credit: [''],
        });
    }

    ngOnInit():void {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.form1.valueChanges.subscribe(form => {
                if(form.amount) {
                    const value = form.amount.toString().replace(/[^0-9]/g,"");
                    this.f['amount'].patchValue(this.decimalPipe.transform(value), { emitEvent: false });
                }
            })
        )

        this.subs.push(
            this.betSlip.getData().subscribe((v:any) => {
                this.fnCalculate()
            })
        )

        this.fnGetData()
    }

    ngOnDestroy():void {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.apiService.get('/cross/set_info').subscribe(jsondata => {
            if(jsondata.success) {
                this.set_max_bet = jsondata.data.max_bet
                this.set_max_win = jsondata.data.max_win
                this.set_min_bet = jsondata.data.min_bet
                this.max_win_line = jsondata.data.max_win_line
            }
        })
    }

    onSubmit() {
        if(this.betSlip.length == 0) {
            (window as any).alert(`카트내역이 없습니다.`)
            return;
        }

        // 한번 더 체크
        this.fnCalculate()

        this.submitted = true;

        const bet_amount = +(this.f['amount'].value.toString().replace(/[^0-9]/g,""));
        const use_credit = this.f['use_credit'].value
        const init_cash = (!use_credit) ? this.currentUser?.cash || 0 : this.currentUser?.credit || 0

        if(bet_amount < this.set_min_bet) this.f['amount'].setErrors({ 'check_error': `최소베팅금액은 ${this.decimalPipe.transform(this.set_min_bet)} 입니다.` });

        // 보유금액 비교
        if(init_cash < bet_amount) {
            if(!use_credit)
                this.f['amount'].setErrors({ 'check_error': `보유금액이 부족합니다` });
            else
                this.f['amount'].setErrors({ 'check_error': `보유크레딧이 부족합니다` });
        }


        if(this.form1.invalid) return;

        if(!confirm(`베팅을 하시겠습니까?`)) return;
        this.sigining = true;

        const params = {
            amount: this.f['amount'].value.toString().replace(/[^0-9]/g,""),
            use_credit: this.f['use_credit'].value,
            cart: this.betSlip.getItems()
        };

        this.apiService.post('/cross/bet_process', params).subscribe(jsondata => {
            this.sigining = false;
            this.submitted = false;
            if(jsondata.message) (window as any).alert(jsondata.message);
            if(jsondata.success) {
                this.f['amount'].patchValue(0)
                this.betSlip.clearItems()

                this.router.navigateByUrl("/bets")
            }
        })
    }

    fnSetAmount(amount:number) {
        let total = 0;
        if(amount > 0) {
            const bet_amount = this.f['amount'].value.toString().replace(/[^0-9]/g,"");
            total = (+bet_amount) + amount;
        } 

        this.f['amount'].patchValue(total)
        this.fnCalculate()
    }

    fnSetMax() {
        if(this.betSlip.length == 0) {
            (window as any).alert('베당을 선택하세요.')
            return;
        }

        const use_credit = this.f['use_credit'].value
        const init_cash = (!use_credit) ? this.currentUser?.cash || 0 : this.currentUser?.credit || 0

        let bet_amount = 0
        const hope_rate = this.hope_rate
        const set_max_bet = this.set_max_bet;
        const set_max_win = this.set_max_win

        if(hope_rate * set_max_bet < set_max_win)
			bet_amount = set_max_bet; //1회 최대베팅금액
		else
			bet_amount = set_max_win / hope_rate;

		//100원단위 절사
		bet_amount = bet_amount - (bet_amount % 100);
		
		// 보유금액
		if(init_cash < bet_amount) bet_amount = init_cash;

        this.f['amount'].patchValue(bet_amount)
        this.fnCalculate()
    }

    fnCalculate() {
        const bet_amount = this.f['amount'].value.toString().replace(/[^0-9]/g,"");

        let total = +(bet_amount);
        const use_credit = this.f['use_credit'].value
        const init_cash = (!use_credit) ? this.currentUser?.cash || 0 : this.currentUser?.credit || 0

        // 보유금액보다 작으면
        if(total > init_cash) total = init_cash;

        // 최대베팅
        if(total > this.set_max_bet) total = this.set_max_bet
        this.f['amount'].patchValue(total)

        if(this.betSlip.length == 0) {
            this.hope_amount = 0
            this.hope_rate = 0
            return;
        }

        let hope_rate = 1;
        this.betSlip.getItems().map((v:any) => {
            hope_rate = hope_rate * +(v.choicePrice)
        })
        this.hope_rate = hope_rate;
        
        this.hope_amount = this.hope_rate * total

        // 원단위 절사
		this.hope_amount = this.hope_amount - (this.hope_amount % 1);

        // 최대 적중
        if(this.hope_amount > this.set_max_win) {
            total = this.set_max_win / this.hope_rate;
            
            // 원단위 절사
            total = total - (total % 1)

            this.hope_amount = this.hope_rate * total

            // 원단위 절사
		    this.hope_amount = this.hope_amount - (this.hope_amount % 1);

            this.f['amount'].patchValue(total)
        }
    }

    fnDelete(index:number | null = null, $event:any = null) {
        if($event) $event.preventDefault()
        if(index) this.betSlip.removeItems(index)
        else {
            this.betSlip.clearItems()
        }
    }
}
