
<main class="w-100 text-center mt-10">
    <form role="form" [formGroup]="form1" class="form-signin m-auto" (ngSubmit)="onSubmit()">
        <a routerLink="/">
            <img src="/assets/images/logo.png" class="w-100 p-5" style="animation: glow 1s infinite alternate;">
        </a>
        <h4 class="mb-3 fw-bold text-white">로그인</h4>
        <div class="form-floating text-start mb-1">
            <input type="text" formControlName="uid" class="form-control form-control-sm" placeholder="아이디" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['uid'].errors }" />
            <label for="uid">아이디</label>
            <div *ngIf="submitted && f['uid'].errors" class="invalid-feedback">
                <div *ngIf="f['uid'].errors['required']">아이디를 입력하세요.</div>
            </div>
        </div>
        <div class="form-floating text-start mb-1">
            <input type="password" formControlName="pwd" class="form-control form-control-sm" placeholder="비밀번호" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['pwd'].errors }" />
            <label for="pwd">비밀번호</label>
            <div *ngIf="submitted && f['pwd'].errors" class="invalid-feedback">
                <div *ngIf="f['pwd'].errors['required']">비밀번호를 입력하세요.</div>
            </div>
        </div>
        <div class="form-floating mb-1">
            <turnstile [siteKey]="siteKey" [action]="siteAction" (resolved)="sendCaptchaResponse($event)" theme="auto" [tabIndex]="0"></turnstile>
        </div>
        <button type="submit" [disabled]="sigining || !recaptchaReady" class="w-100 btn btn-primary">
            <span *ngIf="sigining" class="spinner-border spinner-border-sm me-1"></span>
            로그인
        </button>
        <span class="line_or py-3"><span class="txt_or">또는</span></span>
        <button type="button" [disabled]="sigining" class="w-100 btn btn-secondary p-2 mb-1" (click)="Go($event, '/signup')">
            회원가입
        </button>
        <div *ngIf="error" class="alert alert-danger mt-1">
            <span [innerHTML]="error | sanitize"></span>
        </div>
         <!-- <small>This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">개인정보보호</a> and
                <a href="https://policies.google.com/terms">약관</a> apply.
            </small> -->
    </form>
</main>


