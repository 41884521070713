<div class="text-center">
    <h1 class="error_code">404</h1>
    <h2>Oooooops! You're lost.</h2>
    <p>The page you are looking for does not exist.
      How you got here is a mystery. But you can click the button below
      to go back to the homepage.
    </p>
    <button class="mt-2 btn btn-primary" (click)="goHome()">GO HOME</button>
</div>

