import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TimerService } from 'src/service/timer.service';

@Component({
  selector: 'shared-event-list',
  templateUrl: './event-list.component.html',
  styles: [
  ],
})
export class EventListComponent {
    private subs: Subscription[] = [];
    
    today!:string;
    constructor(
        private timerService: TimerService,
        private router: Router,
    ) {

    }

    ngOnInit(): void {
        this.timerService.getDate().subscribe(d => {
            this.today = d.format('YYYY-MM-DD')
        });
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();

        if(!url) url = ($event.target as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
    
}
