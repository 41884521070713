import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { CommonSharedModule } from 'src/common/common-shared.module';
import { DecimalPipe } from '@angular/common';
import { AuthModule } from './auth/auth.module';
import { WINDOW_PROVIDERS } from 'src/service/windows.service';
import { JwtInterceptorProviders } from 'src/interceptor/jwt.interceptor';
import { ErrorInterceptorProviders } from 'src/interceptor/error.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CommonSharedModule,
    AuthModule,
  ],
  providers: [
    DecimalPipe,
    WINDOW_PROVIDERS,
    JwtInterceptorProviders,
    ErrorInterceptorProviders,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
