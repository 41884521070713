import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
    private loading = new BehaviorSubject<boolean>(false);

    constructor() {}

    public getLoading() {
        return this.loading.asObservable() 
    }

    public setLoading(loading:boolean) {
        this.loading.next(loading);
    }
}