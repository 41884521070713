import { Component, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import { Router } from '@angular/router';
import { ApiService } from 'src/service/api.service';
import { WINDOW } from 'src/service/windows.service';
import { SiteService } from 'src/service/site.service';

@Component({
  selector: 'shared-sign-in',
  templateUrl: './sign-in.component.html',
  styles: ['']
})
export class SignInComponent {
    private subs: Subscription[] = [];

    isOffCanvasCart:boolean = false;
    items_notice:any = []
    currentUser!:currentUserInfo | null

    get window() { return this._window as any }
    get site() { return this.siteService }
    constructor(
        private authService: AuthService,
        private router: Router,
        private apiService: ApiService,
        private siteService: SiteService,
        @Inject(WINDOW) private _window: Window
    ) {}

    ngOnInit() {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();

        if(!url) url = ($event.target as HTMLInputElement).getAttribute('href') || ''

        // this.offcanvasMenu.hide()
        // this.offcanvasCart.hide()
        this.router.navigateByUrl(url);
    }

    fnGetNotice() {
        this.apiService.get("/site/notice").subscribe(jsondata => {
            if(jsondata.success) {
                this.items_notice = jsondata.data
            }
        });
    }

    signOut($event:any | null = null) : void {
        if($event) $event.preventDefault();

        if(confirm('로그아웃 하시겠습니까?')) {
            this.authService.signOut();
            this.router.navigate(['/signout']);
        }
    }
}
