<form role="form" [formGroup]="form1" (ngSubmit)="onSubmit()">
    <div class="row g-0 justify-content-center p-3 min-vh-100">
        <div class="col-12 col-xl-4 row g-0 align-items-center justify-content-center">
            <div class="col-8 p-3">
                <a href="/" (click)="Go($event)">
                    <img src="/assets/images/logo.png" class="w-100" style="animation: glow 1s infinite alternate;">
                </a>
            </div>
            <h5 class="content-header mt-2"><i class="fa-solid fa-user-plus me-1"></i> 회원가입 - Sign Up </h5>
            <div class="col-xl-4 text-yellow">
                추천인 코드
            </div>
            <div class="col-xl-8">
                <input type="text" formControlName="referral" class="form-control form-control-sm" placeholder="추천인코드" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['referral'].errors }" />
                <div *ngIf="submitted && f['referral'].errors" class="invalid-feedback">
                    <div *ngIf="f['referral'].errors['required']">추천인 코드를 입력하세요.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                아이디
            </div>
            <div class="col-xl-8">
                <input type="text" formControlName="uid" class="form-control form-control-sm" placeholder="아이디" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['uid'].errors }" />
                <div *ngIf="submitted && f['uid'].errors" class="invalid-feedback">
                    <div *ngIf="f['uid'].errors['required']">아이디를 입력하세요.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                닉네임
            </div>
            <div class="col-xl-8">
                <input type="text" formControlName="nick" class="form-control form-control-sm" placeholder="닉네임" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['nick'].errors }" />
                <div *ngIf="submitted && f['nick'].errors" class="invalid-feedback">
                    <div *ngIf="f['nick'].errors['required']">닉네임 입력하세요.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                비밀번호
            </div>
            <div class="col-xl-8">                
                <input type="text" formControlName="password" class="form-control form-control-sm" placeholder="비밀번호" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" />
                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                    <div *ngIf="f['password'].errors['required']">비밀번호 입력하세요.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                비밀번호 확인
            </div>
            <div class="col-xl-8">
                <input type="text" formControlName="password_confirm" class="form-control form-control-sm" placeholder="비밀번호 확인" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['password_confirm'].errors }" />
                <div *ngIf="submitted && f['password_confirm'].errors" class="invalid-feedback">
                    <div *ngIf="f['password_confirm'].errors['required']">비밀번호확인을 입력하세요.</div>
                    <div *ngIf="f['password_confirm'].errors['match_error']">비밀번호 확인이 틀립니다.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                휴대폰
            </div>
            <div class="col-xl-8">
                <div class="input-group">
                    <input type="text" formControlName="hp" class="form-control form-control-sm" placeholder="휴대폰" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['hp'].errors }" />
                    <button type="button" class="btn btn-primary btn-sm ms-1">인증번호받기</button>
                    <div *ngIf="submitted && f['hp'].errors" class="invalid-feedback">
                        <div *ngIf="f['hp'].errors['required']">휴대폰번호를 숫자로만 입력하세요.</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                인증번호
            </div>
            <div class="col-xl-8">
                <div class="input-group">
                    <input type="text" formControlName="auth_code" class="form-control form-control-sm" placeholder="인증번호" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['auth_code'].errors }" />
                    <button type="button" class="btn btn-secondary btn-sm ms-1">인증번호 확인</button>
                    <div *ngIf="submitted && f['auth_code'].errors" class="invalid-feedback">
                        <div *ngIf="f['auth_code'].errors['required']">인증번호를 입력하세요.</div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                출금은행
            </div>
            <div class="col-xl-8">
                <ng-select formControlName="bank_name" placeholder="출금은행" [items]="bankList"  bindLabel="name" bindValue="id" [ngClass]="{ 'is-invalid': submitted && f['bank_name'].errors }"></ng-select>
                <div *ngIf="submitted && f['bank_name'].errors" class="invalid-feedback">
                    <div *ngIf="f['bank_name'].errors['required']">출금은행을 선택하세요.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                계좌번호
            </div>
            <div class="col-xl-8">
                <input type="text" formControlName="bank_account" class="form-control form-control-sm" placeholder="출금계좌" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['bank_account'].errors }" />
                <div *ngIf="submitted && f['bank_account'].errors" class="invalid-feedback">
                    <div *ngIf="f['bank_account'].errors['required']">출금계좌를 입력하세요.</div>
                </div>
            </div>
            <div class="col-xl-4 text-yellow">
                예금주
            </div>
            <div class="col-xl-8">
                <input type="text" formControlName="bank_owner" class="form-control form-control-sm" placeholder="예금주" required autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f['bank_owner'].errors }" />
                <div *ngIf="submitted && f['bank_owner'].errors" class="invalid-feedback">
                    <div *ngIf="f['bank_owner'].errors['required']">예금주를 입력하세요.</div>
                </div>
            </div>
            <div class="mb-1 text-center">
                <turnstile [siteKey]="siteKey" [action]="siteAction" (resolved)="sendCaptchaResponse($event)" theme="auto" [tabIndex]="0"></turnstile>
            </div>
            <button type="submit" [disabled]="sigining" class="w-100 btn btn-primary p-2 my-1">
                <span *ngIf="sigining" class="spinner-border spinner-border-sm me-1"></span>
                회원가입
            </button>
            <div class="text-center">
                <span class="line_or py-3 mb-1"><span class="txt_or">또는</span></span>
            </div>
            <button type="submit" [disabled]="sigining" class="w-100 btn btn-secondary p-2 mb-1" (click)="Go($event, '/signin')">
                <span *ngIf="sigining" class="spinner-border spinner-border-sm me-1"></span>
                로그인
            </button>
            <div *ngIf="error" class="alert alert-danger mt-1">{{error}}</div>
            <!-- <small>This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">개인정보보호</a> and
                <a href="https://policies.google.com/terms">약관</a> apply.
            </small> -->
        </div>
    </div>
</form>