import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { fadeIn, fadeOut } from 'src/common/animation'

// import Swiper core and required components
import SwiperCore , {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller,
  } from 'swiper';
import { EventsParams } from 'swiper/angular';

import { MainService, MatchEventItem, PopupItem } from './main.service';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/service/api.service';
    
// install Swiper components
SwiperCore.use([
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Virtual,
    Zoom,
    Autoplay,
    Thumbs,
    Controller
]);

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [
  ],
  animations: [ fadeIn, fadeOut ]
})
export class MainComponent implements OnInit {
    private subs: Subscription[] = [];
    
    show = true;
    // popupItems:PopupItem[] = []
    prematchEventItems:MatchEventItem[] = []
    inplayEventItems:MatchEventItem[] = []

    popups:any


    constructor(
        private mainService: MainService,
        private cookieService: CookieService,
        private router: Router,
        private apiService: ApiService
    ) { }

    ngOnInit(): void {

        this.prematchEventItems = this.mainService.getPreMatchEvents()
        this.inplayEventItems = this.mainService.getInplayEvents()

        this.fnGetData()
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    fnGetData() {
        this.apiService.get("/site/popup").subscribe(jsondata => {
            if(jsondata.success) {
                this.popups = []
                jsondata.data.map((v:any) => {
                    const id = 'popup_layer_' + v.idx;
                    const cookie = sessionStorage.getItem('disable_' + id);
                    if(!cookie) {
                        this.popups.push(v);
                    }
                })
            }
        });
    }

    fnClosePopup(idx:string | undefined) {
        const index = this.popups.findIndex((v:any) => v.idx == idx)
        if(index > -1) this.popups.splice(index, 1);

        const id = 'popup_layer_' + idx;
        sessionStorage.setItem('disable_' + id, '1');
    }

    // 홈팀 1, 원정팀 2
    fnGetTeamPos(items:any, pos:number) {
        return items.filter((v:any) => v.Position == pos)[0]
    }

    onSwiper(params: EventsParams) {
        const swiper = params;
        // console.log(swiper);
    }

    onSlideChange() {
        // console.log('slide change');
    }
}
