import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TimerService } from 'src/service/timer.service';
import { AuthService, currentUserInfo } from '../auth/auth.service';
import * as moment from 'moment-timezone'
import { WINDOW } from 'src/service/windows.service';
import { SiteService } from 'src/service/site.service';
import { ApiService } from 'src/service/api.service';
import { fadeIn, fadeOut } from 'src/common/animation';
import { BetSlipService } from 'src/service/bet-slip.service';

declare const bootstrap: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styles: [''],
  animations: [
    fadeIn,
    fadeOut,
  ],
})
export class HomeComponent implements OnInit {
    private subs: Subscription[] = [];
    
    showPageTop = false; // scroll to top button

    offcanvasMenu:any;
    offcanvasCart:any;
    isOffCanvasCart:boolean = false;
    currentMenu:string = '';

    notify: any = {
        memo: 0,
    }



    currentUser!:currentUserInfo | null
    authedTime = 0;
    tokenExpired!:string | null;
    time!:string;
    


    cart:string = ''

    @HostListener('window:scroll', ['$event']) 
    onScroll($event:any){
        this.showPageTop = (window.scrollY > 100) ? true : false;
    }

    
    get betSlip() { return this.betSlipService }
    get site() { return this.siteService }
    constructor(
        private timerService: TimerService,
        private router: Router,
        private authService: AuthService,
        private betSlipService: BetSlipService,
        private siteService: SiteService,
        
    ) { 
        const url = this.router.routerState.snapshot.url || '';
        this.fnGetNav(url);
    }

    

    ngOnInit(): void {
        this.subs.push(
            this.authService.currentUser.subscribe(v => {
                this.currentUser = v;
            })
        )

        this.subs.push(
            this.timerService.getDate().subscribe(d => {
                this.time = d.format('MM-DD HH:mm:ss')
                const time = d.unix();
                if(this.authedTime + (10) <= time) {
                    this.authedTime = time;

                    if(this.currentUser) {
                        // 토큰 체크하여 만료 10분전에 업데이트
                        this.tokenExpired = this.authService.getTokenExpirationDate()
                        if(this.tokenExpired) {
                            const d1 = moment.tz(time, 'X', 'Asia/Seoul')
                            const d2 = moment.tz(this.tokenExpired, 'Asia/Seoul').add('-10', 'minute')
                            if(d2.isBefore(d1)) {
                                this.authService.updateToken().subscribe(v => {
                                })
                            }
                        }

                        this.authService.authenticated().subscribe((v:any)=> {
                            if(v)  {
                                this.notify = v;
                                if(this.notify?.memo > 0) {
                                    if(this.currentMenu !== 'memo') {
                                        (window as any).alert(`읽지않은 쪽지가 있습니다.`)
                                        this.router.navigate(['/memo'])
                                    }
                                }
                            }
                        })
                    }
                }
            })
        )
        this.subs.push(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    const url = event.url || '';
                    this.fnGetNav(url);
                }
            })
        );
    }

    ngAfterViewInit() {
        const offcanvasMenu = document.getElementById('offcanvasMenu')
        this.offcanvasMenu = new bootstrap.Offcanvas(offcanvasMenu);

        const offcanvasCart = document.getElementById('offcanvasCart')
        this.offcanvasCart = new bootstrap.Offcanvas(offcanvasCart);

        if(offcanvasCart) {
            offcanvasCart.addEventListener('show.bs.offcanvas', () => {
                this.isOffCanvasCart = true
            })

            offcanvasCart.addEventListener('hide.bs.offcanvas', () => {
                this.isOffCanvasCart = false
            })
        }
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();

        if(!url) url = ($event.target as HTMLInputElement).getAttribute('href') || ''

        this.offcanvasMenu.hide()
        this.offcanvasCart.hide()
        this.router.navigateByUrl(url);
    }

    signOut($event:any | null = null) : void {
        if($event) $event.preventDefault();

        if(confirm('로그아웃 하시겠습니까?')) {
            this.authService.signOut();
            this.router.navigate(['/signout']);
        }
    }

    fnGetNav(url:string): void {
        if(url.startsWith('/prematch')) {
            this.currentMenu = 'prematch';
        } else if(url.startsWith('/inplay')) {
            this.currentMenu = 'inplay';
        } else if(url.startsWith('/cross-bet')) {
            this.currentMenu = 'cross-bet';              
        } else if(url.startsWith('/special')) {
            this.currentMenu = 'special';                    
        } else if(url.startsWith('/casino')) {
            this.currentMenu = 'casino';
        } else if(url.startsWith('/mini')) {
            this.currentMenu = 'mini';                     
        } else if(url.startsWith('/result')) {
            this.currentMenu = 'result';
        } else if(url.startsWith('/bets')) {
            this.currentMenu = 'bets';   
        } else if(url.startsWith('/deposit') || url.startsWith('/money/deposit')) {
            this.currentMenu = 'deposit';                  
        } else if(url.startsWith('/withdraw') || url.startsWith('/money/withdraw')) {
            this.currentMenu = 'withdraw';
        } else if(url.startsWith('/event')) {
            this.currentMenu = 'event';            
        } else if(url.startsWith('/community')) {
            this.currentMenu = 'community';
        } else if(url.startsWith('/notice')) {
            this.currentMenu = 'notice';
        } else if(url.startsWith('/customer-center')) {
            this.currentMenu = 'customer-center';   
        } else if(url.startsWith('/memo')) {
            this.currentMenu = 'memo';                                                    
        } else {
            this.currentMenu = '';
        }
    }

    fnScrollToTop() {
        const element = <HTMLElement>document.getElementById("page-top");
        if(element){
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }    
    }
}
