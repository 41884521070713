<div class="d-flex align-items-center mt-2 px-1 py-2 telegram-link" (click)="window.open('https://t.me/inplay2')" >
    <div class="col-3 p-1">
        <img src="/assets/images/telegram_logo.svg" class="w-100">
    </div>
    <div class="col-9 text-center">
        <div class="fs-6">실시간 상담 메신저</div>
        <div class="text-yellow fs-6">@inplay2</div>
    </div>
</div>
<div class="sub-header p-2 mt-2">
    <i class="fa-solid fa-gift"></i>
    공지사항
</div>
<ng-container *ngFor="let items of items_notice; let i = index;">
    <div class="board-items p-2" (click)="Go($event, '/notice/read/' + items.idx)">
        {{ items.subject }}
    </div>
</ng-container>