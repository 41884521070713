    
    
<ng-template [ngIf]="!currentUser">
    <div class="sub-header d-flex align-items-center mb-2 p-2">
        <i class="fa-solid fa-user me-1"></i>
        Member Sign In
    </div>
    <div class="d-flex">
        <div class="col-8">
            <div class="">
                <input class="form-control form-control-sm" type="text" placeholder="아이디">
            </div>
            <div class="mt-1">
                <input class="form-control form-control-sm" type="password" placeholder="비밀번호">
            </div>
        </div>
        <div class="col-4 d-grid ps-1">
            <button type="button" class="btn btn-primary btn-sm">로그인</button>
        </div>
    </div>
    <div class="d-flex my-1">
        <div class="col-12 d-flex">
            <div class="col-6 d-grid">
                <button type="button" class="btn btn-primary btn-sm" routerLink="/signup">
                    <i class="fa-solid fa-user-plus"></i>
                    회원가입
                </button>
            </div>
            <div class="col-6 d-grid ps-1">
                <button type="button" class="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target="#request-modal">
                    <i class="fa fa-edit"></i>
                    비회원문의
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template [ngIf]="currentUser">
    <div class="sub-header p-2 d-flex align-items-center mb-2">
        <i class="fa-solid fa-user me-1"></i>
        Member Info
    </div>
    <div class="d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <span *ngIf="currentUser" class="me-1"><img [attr.src]="site.getIcon(currentUser.level)" style="width:24px;height:24px;" *ngIf="site.getIcon(currentUser.level)"></span>
            <span *ngIf="currentUser">{{ currentUser.nick }}</span>
        </div>
        <button class="btn btn-secondary btn-sm" (click)="signOut()">
            <i class="fa-solid fa-sign-out"></i>
                로그아웃
        </button>
    </div>
    <div class="d-flex justify-content-between mt-1">
        <div class="d-flex align-items-center">
            <i class="fa-solid fa-envelope me-1"></i>
            쪽지함
        </div>
        <span class="badge bg-dark text-sm" routerLink="/memo" role="button">{{ currentUser.memo | number }}</span>
    </div>
    <div class="d-flex justify-content-between mt-1">
        <div class="d-flex align-items-center">
            <i class="fa-solid fa-won-sign me-1"></i>
            보유머니
        </div>
        <span class="text-yellow">{{ currentUser.cash | number}}</span>
    </div>
    <div class="d-flex justify-content-between mt-1">
        <div class="d-flex align-items-center">
            <i class="fa-solid fa-parking me-1"></i>
            보유포인트
        </div>
        <span class="text-primary">{{ currentUser.point | number}}</span>
    </div>
    <div class="d-flex justify-content-between mt-1">
        <div class="d-flex align-items-center">
            <i class="fa-solid fa-cent-sign me-1"></i>
            보유크레딧
        </div>
        <span class="text-danger">{{ currentUser.credit | number}}</span>
    </div>
    <div class="d-flex justify-content-between mt-1">
        <div class="d-flex align-items-center">
            <i class="fa-solid fa-circle-dollar-to-slot me-1"></i>
            카지노머니
        </div>
        <span class="text-info">{{ currentUser.casino_money | number}}</span>
    </div>
    <div class="row mt-1">
        <div class="col-6 d-grid pe-1">
            <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, '/money/deposit')">
                <i class="fa-solid fa-money-bill-alt"></i>
                충전신청
            </button>
        </div>
        <div class="col-6 d-grid ps-0">
            <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/money/withdraw')">
                <i class="fa-solid fa-comments-dollar"></i>
                환전신청
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-6 d-grid pe-1">
            <button type="button" class="btn btn-primary btn-sm" (click)="Go($event,'/money/point')">
                <i class="fa-solid fa-sync"></i>
                포인트전환
            </button>
        </div>
        <div class="col-6 d-grid ps-0">
            <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/memo')">
                <i class="fa-solid fa-envelope"></i>
                쪽지함
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-6 d-grid pe-1">
            <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, 'bets')">
                <i class="fa-solid fa-bars"></i>
                베팅내역
            </button>
        </div>
        <div class="col-6 d-grid ps-0">
            <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/mypage/attendance')">
                <i class="fa-solid fa-clipboard-check"></i>
                출석체크
            </button>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-6 d-grid pe-1">
            <button type="button" class="btn btn-primary btn-sm" (click)="Go($event, '/mypage')">
                <i class="fa-solid fa-user"></i>
                마이페이지
            </button>
        </div>
        <div class="col-6 d-grid ps-0">
            <button type="button" class="btn btn-secondary btn-sm" (click)="Go($event, '/mypage/referral')">
                <i class="fa-solid fa-user-friends"></i>
                추천인내역
            </button>
        </div>
    </div>
</ng-template>
<div class="item-grid">
    <!-- <ng-template [ngIf]="!this.isOffCanvasCart">
        <app-mini-bet-slip></app-mini-bet-slip>
    </ng-template> -->
</div>

