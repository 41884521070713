<ul *ngIf="pagination.pages && pagination.pages.length" class="pagination pagination-sm d-inline-flex">
    <li *ngIf="pagination.first" class="page-item">
        <a href="#" (click)="Go(pagination.first, $event)" [attr.data-page]="pagination.first" class="page-link" title="{{ pagination.first + ' 페이지' }}">처음</a>
    </li>
    <li *ngIf="pagination.prev" class="page-item">
        <a href="#" (click)="Go(pagination.prev, $event)" [attr.data-page]="pagination.prev" class="page-link" title="{{ pagination.prev + ' 페이지' }}">이전</a>
    </li>
    <li *ngFor="let item of pagination.pages" [ngClass]="{'active' : item == pagination.page}"  class="page-item">
        <a href="#" (click)="Go(item, $event)" [attr.data-page]="item" class="page-link" title="{{ item + ' 페이지' }}">{{item}}</a>
    </li>
    <li *ngIf="pagination.next" class="page-item">
        <a href="#" (click)="Go(pagination.next, $event)" [attr.data-page]="pagination.next" class="page-link" title="{{ pagination.next + ' 페이지' }}">다음</a>
    </li>
    <li *ngIf="pagination.last" class="page-item">
        <a href="#" (click)="Go(pagination.last, $event)" [attr.data-page]="pagination.last" class="page-link" title="{{ pagination.last + ' 페이지' }}">끝</a>
    </li>
</ul>