import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { matchValidator } from 'src/common/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styles: []
})
export class SignUpComponent implements OnInit {
    private subscription: Subscription[] = [];

    form1: FormGroup;
    sigining = false;
    submitted = false;
    error = '';

    selectedBank!: number;
    bankList:any;

    siteKey = environment.CLOUDFLARE.TURNSTILE.KEY;
    siteAction = 'user_signin'
    recaptchaReady = false;
    token:string = '';
    
    get f() { return this.form1.controls }
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
    ) { 
        this.form1 = this.formBuilder.group({
            referral: ['', Validators.required],
            uid: ['', Validators.required],
            nick: ['', Validators.required],
            password: ['', Validators.required],
            password_confirm: ['', Validators.required, matchValidator('password')],
            hp: ['', Validators.required],
            auth_code: ['', Validators.required],
            bank_name: ['', Validators.required],
            bank_account: ['', Validators.required],
            bank_owner: ['', Validators.required],
            pwe_withdraw: ['', Validators.required],
        });

        this.bankList = [
            { id: 1, name: 'Volvo' },
            { id: 2, name: 'Saab' },
            { id: 3, name: 'Opel' },
            { id: 4, name: 'Audi' },
        ];

    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        
    }

    ngOnDestroy() {
        this.subscription.map(v => v.unsubscribe());
    }

    onSubmit() {
        this.submitted = true;
        if(this.form1.invalid) return;

        this.sigining = true;
    }

    sendCaptchaResponse(captchaResponse: any) {
        if(captchaResponse) {
            this.recaptchaReady = true;
            this.token = captchaResponse;
        } else {
            this.recaptchaReady = false;
            this.token = '';
        }

        // console.log(`Resolved captcha with response: ${captchaResponse}`);
    }

    raiseError(error:any) {
        this.error = error;
        this.sigining = false;
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();
        if(!url) url = ($event.currentTarget as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
}
