import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInComponent } from './sign-in.component';
import { SignOutComponent } from './sign-out.component';
import { SignUpComponent } from './sign-up.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TurnstileModule } from 'src/common/turnstile/turnstile.module';
import { CommonSharedModule } from 'src/common/common-shared.module';



@NgModule({
  declarations: [
    SignInComponent,
    SignOutComponent,
    SignUpComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    TurnstileModule,
    CommonSharedModule
  ]
})
export class AuthModule { }
