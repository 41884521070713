import {
    Component,
    AfterViewInit,
    ElementRef,
    Input,
    NgZone,
    Output,
    EventEmitter,
    OnDestroy,
  } from '@angular/core';
  import { TurnstileOptions } from './interfaces/turnstile-options';
  
declare global {
    interface Window {
        onloadTurnstileCallback: () => void;
        turnstile: {
            render: (
                idOrContainer: string | HTMLElement,
                options: TurnstileOptions
            ) => string;
            reset: (widgetIdOrContainer: string | HTMLElement) => void;
            getResponse: (
                widgetIdOrContainer: string | HTMLElement
            ) => string | undefined;
            remove: (widgetIdOrContainer: string | HTMLElement) => void;
        };
    }
}

@Component({
    selector: 'turnstile',
    template: ``,
    exportAs: 'turnstile',
})
export class TurnstileComponent implements AfterViewInit, OnDestroy {
    @Input() siteKey!: string;
    @Input() action?: string;
    @Input() cData?: string;
    @Input() theme?: 'light' | 'dark' | 'auto' = 'auto';
    @Input() tabIndex?: number;
    @Input() size?: 'normal' | 'compact' = 'normal';

    @Output() resolved = new EventEmitter<string | null>();
  
    private widgetId!: string;
    private turnstileUrl: string;
  
    constructor(
      private elementRef: ElementRef<HTMLElement>,
      private zone: NgZone
    ) {
        this.turnstileUrl = `https://challenges.cloudflare.com/turnstile/v0/api.js`
    }
  
    ngAfterViewInit(): void {
        const turnstileOptions: TurnstileOptions = {
            sitekey: this.siteKey,
            theme: this.theme,
            tabindex: this.tabIndex,
            action: this.action,
            cData: this.cData,
            size: this.size,
            callback: (token: string) => {
                this.zone.run(() => this.resolved.emit(token));
            },
            'expired-callback': () => {
                this.zone.run(() => this.reset());
            },
        };
    
    
        window['onloadTurnstileCallback'] = () => {
            if (!this.elementRef?.nativeElement) {
                return;
            }
    
            this.widgetId = window.turnstile.render(
                this.elementRef.nativeElement,
                turnstileOptions
            );
        };

        const script = document.createElement('script');
        script.src = `${this.turnstileUrl}?render=explicit&onload=onloadTurnstileCallback`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
    }
  
    reset() {
        if(this.widgetId) {
            this.resolved.emit(null);
            window.turnstile.reset(this.widgetId);
        }
    }
  
    public ngOnDestroy(): void {
        if(this.widgetId) {
            window.turnstile.remove(this.widgetId);
        }
    }
}