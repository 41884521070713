import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WINDOW } from 'src/service/windows.service';
import { NoticeService } from './notice.service';

@Component({
  selector: 'shared-notice',
  templateUrl: './notice.component.html',
  styles: [
  ]
})
export class NoticeComponent {
    private subs: Subscription[] = [];

    isOffCanvasCart:boolean = false;
    items_notice:any = []

    get window() { return this._window as any }
    constructor(
        private router: Router,
        private noticeService: NoticeService,
        @Inject(WINDOW) private _window: Window
    ) {}

    ngOnInit() {
        this.subs.push(
            this.noticeService.getData().subscribe((v:any) => this.items_notice = v)
        )
    }

    ngOnDestroy() {
        this.subs.map(s => s.unsubscribe());
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();

        if(!url) url = ($event.target as HTMLInputElement).getAttribute('href') || ''

        // this.offcanvasMenu.hide()
        // this.offcanvasCart.hide()
        this.router.navigateByUrl(url);
    }
}
