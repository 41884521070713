import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-bets',
  templateUrl: './bets.component.html',
  styles: [
  ]
})
export class BetsComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }


}
