import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { TurnstileComponent } from 'src/common/turnstile/turnstile.component';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SignInComponent implements OnInit {
    private subscription: Subscription[] = [];

    form1: FormGroup;
    sigining = false;
    submitted = false;
    returnUrl!: string;
    error = '';

    // cloudflare turnstile
    siteKey = environment.CLOUDFLARE.TURNSTILE.KEY;
    siteAction = 'user_signIn'
    recaptchaReady = false;
    token:string = '';
    hostname: string = '';

    @ViewChild(TurnstileComponent) ts!:TurnstileComponent; 
    get f() { return this.form1.controls; }
    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private authService: AuthService,
    ) { 
        this.form1 = this.formBuilder.group({
            uid: ['', Validators.required],
            pwd: ['', Validators.required]
        });

        this.returnUrl = sessionStorage.getItem('returnUrl') || '/';
        this.hostname = window.location.hostname.replace('www.', '')

        this.clearStorage();
    }

    ngOnInit(): void {

    }

    ngAfterViewInit() {
        
    }

    ngOnDestroy() {
        this.subscription.map(v => v.unsubscribe());
    }

    clearStorage() {
        const signout = sessionStorage.getItem('signout');
        if(signout) {
            const d = JSON.parse(signout);
            this.error = d.message;

            sessionStorage.removeItem('signout');
        }

        this.authService.clear();
    }

    onSubmit() {
        this.submitted = true;
        if(this.form1.invalid) return;

        console.log("here?");

        this.sigining = true;
        const uid = this.form1.controls['uid'].value
        const pwd = this.form1.controls['pwd'].value

        console.log(this.returnUrl);
        this.authService.signIn(uid, pwd, this.token).subscribe({
            next: (v) => {
                if(v.success) {
                    this.sigining = false;
                    this.router.navigateByUrl(this.returnUrl);
                } else {
                    this.raiseError(v.message);
                }
            },
            error: (err) => {
                this.raiseError(err.message || err);
            },
            complete: () => {
                console.log("hi here i am ")
            } 
        })
    }

    sendCaptchaResponse(captchaResponse: any) {
        this.recaptchaReady = true;
        this.token = captchaResponse;
    }

    raiseError(error:any) {
        this.error = error;
        this.sigining = false;
        this.ts.reset()
    }

    Go($event:any = null, url:string='') {
        if($event) $event.preventDefault();
        if(!url) url = ($event.currentTarget as HTMLInputElement).getAttribute('href') || ''

        this.router.navigateByUrl(url);
    }
}
