import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, lastValueFrom, map, timer } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
    public dataStore:any = new BehaviorSubject<any>({})
    
    constructor(
        private apiService: ApiService
    ) { 
        this.fnGetData('levels')
    }

    public getData() {
        return this.dataStore.asObservable() 
    }
    
    fnGetData(code:string = '') {
        let url = '/site'
        if(code) url += '/' + code

        this.apiService.get(url).subscribe(jsondata => {
            if(jsondata.success && jsondata.data) {
                const dataStore = this.dataStore.getValue()
                dataStore[code] = jsondata.data;
                
                this.dataStore.next(dataStore)
            } 
        })
    }


    getIcon(level:number | undefined) {
        if(!level) return undefined;

        const dataStore = this.dataStore.getValue()
        if(!dataStore['levels']) return undefined


        const k = dataStore['levels'].filter((v:any) => v['level'] == level) 
        if(k) return k[0]['icon']
        else return undefined
    }
}

