import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { ApiService } from 'src/service/api.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as moment from 'moment-timezone'

export interface currentUserInfo {
    idx?: number;
    uid?: number;
    nick?: string;
    point?: number;
    cash?: number;
    credit?: number;
    casino_money?: number;
    memo?: number;
    access_token?: string;
    refresh_token?: string;
    role?: number;
    level?: number;
    signedAt?: Date;
}

export const userRole =  {
    LEVEL1 : 1,
    LEVEL2: 2,
    LEVEL3: 4,
    LEVEL4: 8,
    LEVEL5: 16,
    LEVEL6: 32,
    LEVEL7: 64,
    LEVEL8: 128,
    LEVEL9: 256,
    LEVEL10: 512,
    LEVEL11: 1024,
    LEVEL12: 2048,
    LEVEL13: 5096,
    LEVEL14: 10192,
    LEVEL15: 20384,
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
    public currentUser = new BehaviorSubject<currentUserInfo | null>(null)
    hostname:string;
    constructor(
        private apiService:ApiService,
        private jwtHelperService: JwtHelperService,
    ) { 
        this.hostname = window.location.hostname.replace('www.', '')
        const currentUser =  sessionStorage.getItem('currentUser');
        if(currentUser) {
            this.currentUser.next(JSON.parse(currentUser));
        }
    }

    clear(currentUser:boolean = true) {
        sessionStorage.removeItem('currentUser')
        sessionStorage.removeItem('access_token')
        sessionStorage.removeItem('refresh_token')
        sessionStorage.clear();

        if(currentUser) this.currentUser.next(null);
    }

    set(data:any) {
        const currentUser:currentUserInfo = {  ...this.currentUser.getValue(), ...data };
        sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.currentUser.next(currentUser);    
    }

    signIn(uid: string, pwd: string, captcha:string) {
        const token = sessionStorage.getItem('currentToken')

        return this.apiService.post('/auth/signin', { uid: uid, pwd: pwd, 'cf-turnstile-response': captcha, token: token }).pipe(map(jsondata => {            
            if(jsondata.success) {
                const access_token = jsondata.data.access_token;
                const refresh_token = jsondata.data.refresh_token;
        
                this.clear()

                sessionStorage.setItem('currentUser', JSON.stringify(jsondata.data));
                sessionStorage.setItem('access_token', access_token);
                sessionStorage.setItem('refresh_token', refresh_token);

                this.currentUser.next(jsondata.data);
            } 
            
            return jsondata;
        }))
    }


    signOut() {
        const subject = new Subject<boolean>();
        let succeed = false;
        if (!this.currentUser) {
            subject.next(false);
        } else {
            this.apiService.get('/auth/signout').subscribe({
                next: (v) => {
                    if(v.success) {
                        succeed = true;
                    } 
                },
                error: (err) => {
                    console.log('isAuthenticated err: ', err.message || err);
                },
                complete: () => {
                    this.clear() // clear cookie
                    subject.next(succeed);
                } 
            })
        }

        return subject.asObservable();
    }

    updateToken() {
        return this.apiService.post('/auth/update-token').pipe(map(jsondata => {
            if(jsondata.success) {
                const access_token = jsondata.data.access_token;
                const refresh_token = jsondata.data.refresh_token;
        
                sessionStorage.setItem('access_token', access_token);
                sessionStorage.setItem('refresh_token', refresh_token);
            } 
            
            return jsondata;
        }))
    }

    authenticated() {
        return this.apiService.get("/auth/authenticated", false).pipe(map(jsondata => {
            if(jsondata.success && jsondata.data) {
                this.set(jsondata.data.users);
            } 

            return jsondata.data.notify;
        }))
    }

    getTokenExpirationDate(): string | null {
        const access_token = sessionStorage.getItem('access_token')
        if(!access_token) return null;
        
        const decodedToken = this.jwtHelperService.decodeToken(access_token);
            if(decodedToken.exp === undefined) return null;

        const d = moment.utc(decodedToken.exp, 'X').tz('Asia/Seoul')
        return d.format('YYYY-MM-DD HH:mm:ss')
    }
}
