import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable, switchMap, timeout  } from 'rxjs';
import { environment } from 'src/environments/environment';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number
    ) {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
        const timeoutValueNumeric = Number(timeoutValue);

        const access_token = sessionStorage.getItem('access_token')
        const refresh_token = sessionStorage.getItem('refresh_token')

        const isApiUrl = request.url.startsWith(environment.end_point);
        const isUpdateTokenUrl = request.url.startsWith(environment.end_point + '/auth/update-token');
        const token = (!isUpdateTokenUrl) ? access_token : refresh_token

        if(token && isApiUrl) {
            request = request.clone({
                headers: request.headers.set(`Authorization`,`Bearer ${token}`)
            });
        }

        return next.handle(request)
    }
}

export const JwtInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 5000 },
];
