import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDirective } from './directive/loading.directive';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { NumbersPipe } from './pipe/numbers.pipe';
import { MomentPipe } from './pipe/moment.pipe';
import { PaginationComponent } from './pagination/pagination.component';
import { SanitizePipe } from './pipe/sanitize.pipe';
import { LoadingComponent } from './loading/loading.component';

@NgModule({
  declarations: [
    LoadingDirective,
    NumbersOnlyDirective,
    NumbersPipe,
    MomentPipe,
    SanitizePipe,
    PaginationComponent,
    LoadingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    LoadingDirective,
    NumbersOnlyDirective,
    NumbersPipe,
    MomentPipe,
    SanitizePipe,
    PaginationComponent,
    LoadingComponent
  ]
})
export class CommonSharedModule { }
