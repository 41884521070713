import { Injectable } from '@angular/core';

export interface PopupItem {
    id?: string,
    idx: number,
    subject?: string,
    img?: string,
    content?: string,
    show?:boolean,
}

export interface Participant {
    Id: number, 
    Name: string, 
    Position: number, 
    IsActive?: number, 
    ExtraData?: []
}

export interface Fixture {
    Id: number,
    Sport: { Id: number, Name: string },
    Competition: { Id: number, Name: string },
    League: { Id: number, Name: string },
    Startdate: string,
    Status: number,
    Participant: Participant[]
    LastUpdate?:string
}

export interface Market {

}

export interface Livescore {

}

export interface MatchEventItem {
    FixtureId: number,
    Fixture: Fixture
    Markets?: Market
    Livescore?: Livescore
}

@Injectable({
  providedIn: 'root'
})
export class MainService {
    private popups = new Array<PopupItem>(
        { idx:1, img: '/assets/popup/popup.jpg', show: true },
        { idx:2, img: '/assets/popup/eb07f4c5d633e591e2d3d0fb2d47d387.png', show: true  },
    )

    private prematchs:MatchEventItem[] = [
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'FIFA' }, 
                League: { Id: 4, Name: 'WORLDCUP 카타르 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '한국', Position: 1 },
                    { Id: 2, Name: '우루과이', Position: 2 },
                ]
            }
        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'FIFA' }, 
                League: { Id: 4, Name: 'WORLDCUP 카타르 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '한국', Position: 1 },
                    { Id: 2, Name: '우루과이', Position: 2 },
                ]
            }
        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'FIFA' }, 
                League: { Id: 4, Name: 'WORLDCUP 카타르 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '한국', Position: 1 },
                    { Id: 2, Name: '카타르 2022', Position: 2 },
                ]
            }
        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'FIFA ' }, 
                League: { Id: 4, Name: 'WORLDCUP 카타르 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '한국', Position: 1 },
                    { Id: 2, Name: '우루과이', Position: 2 },
                ]
            }
        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'FIFA' }, 
                League: { Id: 4, Name: 'WORLDCUP 카타르 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '한국', Position: 1 },
                    { Id: 2, Name: '우루과이', Position: 2 },
                ]
            },
            Livescore: {

            }
        },

    ]

    private inplays:MatchEventItem[] = [
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: '월드챔피언쉽' }, 
                League: { Id: 4, Name: '유럽 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '이탈리아', Position: 1 },
                    { Id: 2, Name: '마케도니아', Position: 2 },
                ]
            },

        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: '월드챔피언쉽' }, 
                League: { Id: 4, Name: '유럽 2022' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '이탈리아', Position: 1 },
                    { Id: 2, Name: '마케도니아', Position: 2 },
                ]
            },

        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'UEFA' }, 
                League: { Id: 4, Name: '챔피언스리그' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '파리 생제르맹', Position: 1 },
                    { Id: 2, Name: '레알 마드리드', Position: 2 },
                ]
            },

        },
        { 
            FixtureId: 1,
            Fixture: {
                Id: 1,
                Sport: { Id: 1, Name: '축구' },  
                Competition: { Id: 1, Name: 'UEFA' }, 
                League: { Id: 4, Name: '챔피언스리그' }, 
                Startdate: '2022-01-22 11:11:11', 
                Status: 1, 
                Participant: [
                    { Id: 1, Name: '파리 생제르맹', Position: 1 },
                    { Id: 2, Name: '레알 마드리드', Position: 2 },
                ]
            },

        },
    ]

    constructor(

    ) { }

    getPopupItems():PopupItem[] {
        return this.popups;
    }

    getPopupItem(idx: number):PopupItem {
        return this.popups.filter(item => item.idx == idx)[0];
    }
    
    getPreMatchEvents():MatchEventItem[] {
        return this.prematchs;
    }

    getPreMatchEvent(fixtureId: number):MatchEventItem {
        return this.prematchs.filter(item => item.FixtureId == fixtureId)[0];
    }

    getInplayEvents():MatchEventItem[] {
        return this.inplays;
    }

    getInplayEvent(fixtureId: number):MatchEventItem {
        return this.inplays.filter(item => item.FixtureId == fixtureId)[0];
    }
}
