import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styles: [`
        h1.error_code {
            font-size: 7.5rem;
            margin: 1rem 0;
            font-weight: bold;
        }
    `]
})
export class ErrorComponent implements OnInit {

    constructor(
        private router: Router,
    ) { }

    ngOnInit(): void {
    }

    goHome() {
        this.router.navigateByUrl('/');
    }
}
