import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';

export const AuthGuard: CanActivateFn = (
        route:ActivatedRouteSnapshot, 
        state:RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
        
    const router = inject(Router)
    const authService = inject(AuthService)
    return new Promise((resolve) => {
        const currentUser = authService.currentUser.getValue()
        const expectedRole = route.data['role'];
        const role = currentUser?.role;
        if(!currentUser || !role) {
            sessionStorage.setItem('returnUrl', state.url);
            router.navigateByUrl('/signin');
    
            resolve(false);
        } else {
            if(expectedRole <= role) {
                resolve(true);
            } else {
                sessionStorage.setItem('returnUrl', state.url);
                router.navigate(['signin-2fa']);
                resolve(false);
            }
        }
    })
};
