<div class="container-fluid">
    <div class="row">
        <div class="d-none d-xl-block col-xl-2 px-0">
            <shared-event-list></shared-event-list>
        </div>
        <div class="col-xl-8">
            <h5 class="content-header"><i class="fa-solid fa-envelope me-1"></i> 쪽지함 - Memo</h5>
            <router-outlet></router-outlet>
        </div>
        <div class="d-none d-xl-block col-xl-2 text-white ps-0">
            <shared-sign-in></shared-sign-in>
            <shared-notice></shared-notice>
        </div>
    </div>
</div>