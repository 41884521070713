import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitize'
})
export class SanitizePipe implements PipeTransform {

    constructor(
        private domSanitizer:DomSanitizer
    ) {

    }

    /**
     * @param args 
     * {{v | sanitize: 'url' }}
     */
    transform(v: string, args: any | null = null) : SafeHtml {
        if(args == 'url') {
            return this.domSanitizer.bypassSecurityTrustResourceUrl(v);
        } else if(args == 'strip_tags') {
            let value = v;
            if(v) value = this.htmlEncode(v);
            return this.domSanitizer.bypassSecurityTrustHtml(value)
        } else {
            return this.domSanitizer.bypassSecurityTrustHtml(v);
        }
    }

    htmlEncode(v:string) {
        if(!v) return ''

        return String(v)
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
       
    }

}
