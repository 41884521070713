import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styles: [
  ]
})
export class CasinoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
